.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.adaafasfasdfasdf::-webkit-outer-spin-button,
.adaafasfasdfasdf::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.adaafasfasdfasdf[type="number"] {
    -moz-apperance: textfield;
}

.cursor-pointer {
    cursor: pointer;
}

.w-25 {
    width: 80% !important;
}

html::-webkit-scrollbar, .scrollbar-hide::-webkit-scrollbar {
    display: none;
}

html, .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none; 
}

@media only screen and (min-width: 768px) {
    .w-25 {
        width: 25% !important;
    }
}

.vh-75 {
    height: 75vh;
}