#answerInput {
    width: 100%;
}

.btop {
    border-top: 1px solid #dee2e6
}

.answerOption {
    background: #ffffff;
    border: 1px solid #ffffff;
}

.answerOption:hover {
    background: #dee2e6;
}

.answerOption:active {
    border: 1px solid black;
}

@media only screen and (min-width: 600px) {
    #answerInput {
        width: 50%;
    }
}

@media only screen and (min-width: 800px) {
    #answerInput {
        width: 25%;
    }
}