@media only screen and (max-width: 600px) {
    #qpracmdesktop {
        display: none;
    }
    #qpracddesktop {
        display: none;
    }
    .chooseBtn {
        width: 75%;
    }
    #chooseDSpace {
        display: none;
    }
}

@media only screen and (min-width: 601px) {
    #qpracmmobile {
        display: none;
    }
    #qpracdmobile {
        display: none;
    }
    .chooseBR {
        display: none;
    }
}

.adaafasfasdfasdf::-webkit-outer-spin-button, .adaafasfasdfasdf::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.adaafasfasdfasdf[type=number] {
    -moz-apperance: textfield;
}

.adsf {
    position: fixed;
    bottom: 0;
    background: black;
    color: white;
    width: 100%;
    padding: 5px;
}